import React from 'react'

export default function Loder() {
    return (
        <div className="loading">
            <span className="start-rotate
            ">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" id="loading"><path d="M46 25.7c-2.2 0-4-1.8-4-4V4.5c0-2.2 1.8-4 4-4s4 1.8 4 4v17.2c0 2.2-1.8 4-4 4zm-11.9 3.9c1.8-1.3 2.2-3.8.9-5.6L24.8 10.1c-1.3-1.8-3.8-2.2-5.6-.9-1.8 1.3-2.2 3.8-.9 5.6l10.1 13.9c.8 1.1 2 1.6 3.2 1.6.9.1 1.8-.2 2.5-.7zm-7.4 10.1c.7-2.1-.5-4.4-2.6-5L7.7 29.4c-2.1-.7-4.4.5-5 2.6-.7 2 .5 4.3 2.6 5l16.4 5.3c.4.1.8.2 1.2.2 1.7 0 3.2-1.1 3.8-2.8zm-19 22.9l16.4-5.3c2.1-.7 3.3-2.9 2.6-5-.7-2.1-2.9-3.2-5-2.6L5.3 55c-2.1.7-3.3 2.9-2.6 5 .5 1.7 2.1 2.8 3.8 2.8.4 0 .8 0 1.2-.2zm17.1 19.3L35 68c1.3-1.8.9-4.3-.9-5.6s-4.3-.9-5.6.9L18.4 77.2c-1.3 1.8-.9 4.3.9 5.6.7.5 1.5.8 2.3.8 1.2 0 2.4-.6 3.2-1.7zM50 87.5V70.3c0-2.2-1.8-4-4-4s-4 1.8-4 4v17.2c0 2.2 1.8 4 4 4s4-1.8 4-4zm22.8-4.7c1.8-1.3 2.2-3.8.9-5.6L63.5 63.3c-1.3-1.8-3.8-2.2-5.6-.9-1.8 1.3-2.2 3.8-.9 5.6l10.1 13.9c.8 1.1 2 1.6 3.2 1.6.9.1 1.8-.2 2.5-.7zm16.5-22.7c.7-2.1-.5-4.4-2.6-5l-16.4-5.3c-2.1-.7-4.4.5-5 2.6-.7 2.1.5 4.4 2.6 5l16.4 5.3c.4.1.8.2 1.2.2 1.7-.1 3.3-1.2 3.8-2.8zm-19-17.8L86.7 37c2.1-.7 3.3-2.9 2.6-5-.7-2.1-2.9-3.2-5-2.6l-16.4 5.3c-2.1.7-3.3 2.9-2.6 5 .5 1.7 2.1 2.8 3.8 2.8.4 0 .8-.1 1.2-.2zm-6.8-13.6l10.1-13.9c1.3-1.8.9-4.3-.9-5.6-1.8-1.3-4.3-.9-5.6.9L57 24c-1.3 1.8-.9 4.3.9 5.6.7.5 1.5.8 2.3.8 1.3 0 2.5-.6 3.3-1.7z"></path></svg> */}
                {/* <svg viewBox='0 0 200 200'><path fill='#fff' stroke='#FFf' stroke-width='15' transform-origin='center' d='m148 84.7 13.8-8-10-17.3-13.8 8a50 50 0 0 0-27.4-15.9v-16h-20v16A50 50 0 0 0 63 67.4l-13.8-8-10 17.3 13.8 8a50 50 0 0 0 0 31.7l-13.8 8 10 17.3 13.8-8a50 50 0 0 0 27.5 15.9v16h20v-16a50 50 0 0 0 27.4-15.9l13.8 8 10-17.3-13.8-8a50 50 0 0 0 0-31.7Zm-47.5 50.8a35 35 0 1 1 0-70 35 35 0 0 1 0 70Z'><animateTransform type='rotate' attributeName='transform' calcMode='spline' dur='2' values='0;120' keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite'></animateTransform></path></svg> */}
                <svg width="38" height="38" viewBox="0 0 38 38" stroke="#fff">
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite" />
                            </path>
                        </g>
                    </g>
                </svg>
            </span>
        </div>
    )
}
